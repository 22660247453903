import React, { Component } from "react";
import Cookies from "universal-cookie";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import Menu from "./menu.jsx";
import Footer from "./footer.jsx";

import "../styles/theme.scss";

const cookies = new Cookies();

class Layout extends Component {
  state = { show_cookie_panel: !!!cookies.get("cookie_accepted") };
  render() {
    const { children, menu, footerMenu, title } = this.props;

    return (
      <>
        <Helmet>
          {title && <title>{title}</title>}
          <meta name="google-site-verification" content="cvs66j9YcRj5_2yeqZKBE5afRRmhWlLrUC_N0dKdrMY" />
        </Helmet>
        <Menu data={menu} />
        <main>{children}</main>
        <Footer data={footerMenu} />
        {this.state.show_cookie_panel && (
          <div className="cookie-panel">
            <div className="cookie-panel__wrapper main-wrapper">
              <p className="cookie-panel__info">
                Użytkowanie tej strony oznacza zgodę na wykorzystywanie plików
                cookies w celu usprawnienia działania witryny oraz tworzenia
                usług i ofert dostosowanych do Twoich potrzeb. Aby uzyskać
                więcej informacji, zapoznaj się z naszą{" "}
                <Link to="/pl/polityka-prywatnosci">polityką prywatności.</Link>
              </p>
              <button
                className="btn-red"
                onClick={() => {
                  cookies.set("cookie_accepted", true, {
                    path: "/",
                    maxAge: 3600,
                  });
                  this.setState({ show_cookie_panel: false });
                }}>
                <strong>Akceptuję</strong>
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default Layout;
