import React, { Component } from "react";
import { Link } from "gatsby";

class Footer extends Component {
  state = {};
  render() {
    return (
      <footer>
        <div className="main-wrapper-md">
          <ul className="footer-menu">
            {this.props.data &&
              this.props.data.map(({ name, id, href }) => (
                <li key={id}>
                  <Link to={href}>{name}</Link>
                </li>
              ))}
          </ul>
          <div className="logo-holder">
            <img src={require("../images/logotype.svg")} alt="PPP Logotype" />
          </div>
          <div className="copyrights">
            <p>© 2022 Posadzki Przemysłowe - Polska Sp. z o. o.</p>
            <p>
              All Rights Reserved. Designed & Developed by{" "}
              <a href="http://projets.pl">Projets.pl</a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
