import React, { Component } from "react";
import { Link } from "gatsby";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { isOpen } = this.state;
    const { data } = this.props;
    return (
      <>
        <button
          className="btn-menu menu-opener"
          onClick={() => this.setState({ isOpen: true })}
        >
          <img
            src={require("../images/menu-icon.svg")}
            style={{ width: "36px" }}
            alt="menu hamburger"
          />
        </button>
        <div
          className={`menu-outer ${isOpen ? "open" : ""}`}
        >
          <aside className={`menu-panel`}>
            <img
              className="menu-panel-logo"
              src={require("../images/logotype.svg")}
              alt="Logotype"
            />
            <button
              className="btn-menu menu-closer"
              onClick={() =>
                this.setState({ isOpen: false })
              }
            >
              <img
                src={require("../images/close-icon.svg")}
                style={{ width: "23px" }}
                alt="menu hamburger"
              />
            </button>
            <ul className="menu-list">
              {data &&
                data
                  .sort((a, b) => a.position - b.position)
                  .map(({ name, id, href }) => (
                    <li key={id}>
                      <Link to={href}>{name}</Link>
                    </li>
                  ))}
            </ul>
            <div className="menu-contact">
              <div className="contact-group">
                <span>Napisz do nas</span>
                <a
                  className="mail"
                  href="mailto:biuro@ppp-posadzki.com.pl"
                >
                  biuro@ppp-posadzki.com.pl
                </a>
              </div>
              <div className="contact-group">
                <span>lub zadzwoń</span>
                <a className="tel" href="tel:+48122511161">
                  +48 12 251 11 61
                </a>
              </div>
            </div>
            <div className="menu-contact-mobile">
              <div className="contact-group">
                <span>Napisz do nas</span>
                <a
                  className="btn-red"
                  href="mailto:biuro@ppp-posadzki.com.pl"
                >
                  <span>biuro@ppp-posadzki.com.pl</span>
                </a>
              </div>
              <div className="contact-group">
                <span>lub zadzwoń</span>
                <a
                  className="btn-red"
                  href="tel:+48122511161"
                >
                  <span>+48 12 251 11 61</span>
                </a>
              </div>
            </div>
          </aside>
        </div>
      </>
    );
  }
}

export default Menu;
